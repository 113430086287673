/* GrapthLikeBinance.css */
.tradingview-widget-container {
    width: 100%;
    height: 100vh;
    /* Ocupa toda a altura da viewport */
    position: relative;
}

.tradingview-widget-container__widget {
    width: 100%;
    height: 100%;
    position: absolute;
}